@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

@tailwind base;
@tailwind components;

@layer components {
  .app-nav-container {
    @apply max-w-7xl mx-auto p-6 sm:p-8;
  }

  .app-main-container {
    @apply max-w-6xl mx-auto px-4 py-6 sm:px-12 sm:py-8;
  }

  .app-main-small-container {
    @apply max-w-3xl mx-auto px-4 py-4 sm:px-8 sm:py-8;
  }

  .app-animation-translate-start {
    @apply translate-y-1/2 opacity-0;
  }

  .app-animation-translate-left-start {
    @apply translate-x-1/2 opacity-0;
  }

  .app-animation-translate-right-start {
    @apply -translate-x-1/2 opacity-0;
  }

  .app-animation-scale-start {
    @apply scale-50 opacity-0;
  }

  .app-animation-opacity-start {
    @apply opacity-50;
  }

  .app-animation-end {
    @apply translate-y-0 translate-x-0 scale-100 opacity-100 transition-all duration-500;
  }

  .switch {
    position: relative;
    display: inline-block;
    height: calc(16px + 2px * 2);
    width: calc(16px * 2 + 2px * 2);
    min-width: calc(16px * 2 + 2px * 2);
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .switch input:checked+.slider {
    background-color: #418dff;
  }

  .switch input:focus+.slider {
    box-shadow: 0 0 1px black;
  }

  .switch input:checked+.slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cccccc;
    -webkit-transition: 0.15s;
    transition: 0.15s;

    border-radius: calc(16px + 2px * 2);
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  .dropdown-nav {
    animation: slidein 1s;
  }

  @keyframes slidein {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }

    to {
      transform: translateX(0%);
      opacity: 1;
    }
  }
}

@tailwind utilities;